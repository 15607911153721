const fallbackFont = "sans-serif";

const fontStyle = {
  weight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  family: {
    futuraPT: `'Futura PT', ${fallbackFont}`,
    montserrat: `system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";`,
    centuryGothic: `'Century Gothic', ${fallbackFont}`,
  },
};

export default fontStyle;
