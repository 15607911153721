import { toast } from "react-toastify";

import Toast from "./Toast";

export default class AppToast {
  info(message: string) {
    return toast.info(<Toast title={message} type="info" />);
  }
  success(message: string) {
    return toast.success(<Toast title={message} type="success" />);
  }
  warning(message: string) {
    return toast.warning(<Toast title={message} type="warning" />);
  }
  error(message: string) {
    return toast.error(<Toast title={message} type="error" />);
  }
  default(message: string) {
    return toast(<Toast title={message} type="guid" />);
  }
}
