const layout: any = {
  breakpoints: {
    xxs: 320,
    xs: 355,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  breakpointMin: (name: keyof typeof layout.breakpoints) => {
    const min = layout.breakpoints[name];
    return min != 0 ? min : null;
  },

  breakpointMax: (name: keyof typeof layout.breakpoints) => {
    const max = layout.breakpoints[name];
    return max && max > 0 ? max - 0.02 : null;
  },

  mediaBreakpointUp: (name: string) => {
    const min = layout.breakpointMin(name);
    if (min) {
      return `@media only screen and (min-width: ${min}px)`;
    }
  },

  mediaBreakpointDown: (name: string) => {
    const max = layout.breakpointMax(name);
    if (max) {
      return `@media only screen and (max-width: ${max}px)`;
    }
  },
};

export default layout;
