export const baseFontSize = 12; //in px
const fontScale = 17;

const pxToRem = (px: number): number => {
  return px / 16;
};

const getTypeSize = (step: number): number => {
  if (step == 1) {
    return baseFontSize;
  }
  return getTypeSize(step - 1) + (Math.floor((step - 2) * 0.25) + 1) * 2;
};

const fontSize: { [key: string]: string } = {};

for (let i = 1; i <= fontScale; i++) {
  fontSize[i] = `${pxToRem(getTypeSize(i))}rem`;
}

export default fontSize;
