import { css } from "@emotion/react";
import React from "react";

import icons from "./iconsList";

interface Props {
  name: string;
  size?: string;
  className?: string;
}

const Icon = (props: Props) => {
  const { name, size, className } = props;

  const iconStyle = css`
    width: 1.2em;
    height: 1.2em;

    /* sizes */
    &.icon.icon--large {
      width: 2em;
      height: 2em;
    }
    &.icon.icon--medium {
      width: 1.6em;
      height: 1.6em;
    }
    &.icon.icon--normal {
      width: 1.2em;
      height: 1.2em;
    }
    &.icon.icon--small {
      width: 1em;
      height: 1em;
    }
    &.icon.icon--tiny {
      width: 0.85em;
      height: 0.85em;
    }
  `;

  return (
    <svg
      css={iconStyle}
      className={`icon icon--${size} ${className ?? ""}`}
      viewBox="0 0 24 24"
    >
      <title id={name}>{name}</title>
      <path fill="currentColor" d={icons[name]} />
    </svg>
  );
};

export default Icon;
