import "../assets/fonts.css";

import { css } from "@emotion/react";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import Router from "next/router";
import NProgress from "nprogress"; //nprogress module
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "nprogress/nprogress.css"; //styles of nprogress

import "../lib/dayjs";
import AppToast from "@components/Toast/AppToast";
import layout from "@variables/layout";
import ApiClient from "src/lib/ApiClient";
import ApiContextProvider from "src/lib/contexts/ApiContextProvider";

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? "",
};

export const appToast = new AppToast();
Router.events.on("routeChangeStart", NProgress.start);
Router.events.on("routeChangeComplete", NProgress.done);
Router.events.on("routeChangeError", NProgress.done);

function MyApp({ Component, pageProps: { ...pageProps } }: AppProps) {
  const apiClient = new ApiClient(`${process.env.NEXT_PUBLIC_API_URL}`);

  useEffect(() => {
    process.env.NEXT_PUBLIC_VERCEL_ENV === "production" &&
      TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <ApiContextProvider apiClient={apiClient}>
      <ToastContainer
        pauseOnHover
        draggable={false}
        autoClose={2000}
        hideProgressBar={true}
        icon={false}
        position="bottom-right"
        closeButton={false}
        closeOnClick={true}
        css={css`
          .Toastify__toast-body > div > div {
            width: 100%;
            ${layout.mediaBreakpointDown("md")} {
              width: fit-content;
              justify-content: flex-end;
            }
          }
        `}
      />
      <Component {...pageProps} />
      <div
        id="portal"
        css={css`
          z-index: 5000;
          position: relative;
        `}
      ></div>
    </ApiContextProvider>
  );
}

export default appWithTranslation(MyApp);
