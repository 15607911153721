/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { ElementType, FC } from "react";

interface ComponentProps {
  as?: ElementType;
  content?: string | undefined;
  ref?: any;
  className?: any;
}

const Typography: FC<ComponentProps> = ({
  as: Tag = "h1",
  content,
  ...otherProps
}) => {
  return <Tag {...otherProps}>{content}</Tag>;
};

export default Typography;
