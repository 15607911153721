import { css, SerializedStyles } from "@emotion/react";

import Icon from "@components/Icon";
import Typography from "@components/Typography";
import colors from "@variables/colors";
import fontSize from "@variables/font-size";
import fontStyle from "@variables/font-style";
import layout from "@variables/layout";
import padding from "@variables/paddings";

type toastType = "warning" | "error" | "info" | "guid" | "success";

type toastObject = {
  color: string;
  icon: string;
  message?: string;
};

const theme: { [key in toastType]: toastObject } = {
  warning: {
    color: colors.yellow[100],
    icon: "warning",
    message: "warning",
  },
  error: {
    color: colors.red[500],
    icon: "error",
    message: "error",
  },
  info: {
    color: colors.blue[500],
    icon: "info",
    message: "info",
  },
  success: {
    color: colors.green[100],
    icon: "circleCheck",
    message: "success",
  },
  guid: {
    color: colors.blue[200],
    icon: "guid",
    message: "guid",
  },
};
interface Props {
  type: toastType;
  title?: string;
  message?: string;
  css?: SerializedStyles;
}

const Toast = ({ type, title, message, ...otherProps }: Props) => {
  const style = css`
    display: flex;
    gap: 1em;
    padding: 12px 8px;
    width: fit-content;
    font-family: ${fontStyle.family["montserrat"]};
    font-size: ${fontSize[1]};
    font-weight: 600;
    border: 2px solid ${theme[type].color};
    border-radius: 8px;
    color: ${theme[type].color};
    background: ${colors.white};
  `;

  const titleCss = css`
    flex: 1;
    color: ${colors.gray[800]};
    font-weight: ${fontStyle.weight["medium"]};
    padding-top: 6px;
  `;
  const messageCss = css`
    font-weight: ${fontStyle.weight["medium"]};
    padding-top: ${title ? padding[4] : "0"};
    color: ${type === "guid" && colors.gray[400]};
  `;

  const titleWrapperCss = css`
    display: flex;
    gap: 0.5em;
  `;

  return (
    <div css={style} {...otherProps}>
      <div>
        <div css={titleWrapperCss}>
          <Icon
            name={theme[type].icon}
            size="medium"
            css={css`
              min-width: fit-content;
              ${layout.mediaBreakpointDown("md")} {
                min-width: auto;
                max-width: 50px;
              }
            `}
          />
          <div>
            {theme[type].message && (
              <Typography
                as="h2"
                content={`${theme[type].message}`}
                css={css`
                  text-transform: capitalize;
                `}
              />
            )}
            <Typography as="h3" content={title} css={titleCss} />
            {message && (
              <Typography as="p" content={message} css={messageCss} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toast;
