import React from "react";

import ApiClient from "../ApiClient";

const ApiContext = React.createContext({
  user: null as any,
  setUser: (user: any) => {},
  clinic: null as any,
  setClinic: (clinic: any) => {},
  userPermissions: [] as any,
  setUserPermissions: (userPermissions: any) => {},
  apiClient: null as any,
  setApiClient: (apiClient: ApiClient) => {},
  globalLoading: false,
  setGlobalLoading: (loading: boolean) => {},
});

export default ApiContext;
