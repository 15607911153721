import inst, { PluginFunc, UnitType } from "dayjs";

declare module "dayjs" {
  interface Dayjs {
    floor(unit: UnitType, amount: number): inst.Dayjs;
  }
}

const floor: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.floor = function (unit: any, amount: number) {
    return this.subtract(this.get(unit) % amount, unit).startOf(unit);
  };
};

export default floor;
