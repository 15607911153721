import { setCookies } from "cookies-next";
import React, { useEffect, useState } from "react";

import ApiClient from "../ApiClient";

import ApiContext from "./ApiContext";

const ApiContextProvider = (props: {
  apiClient: ApiClient;
  children: React.ReactNode;
}): JSX.Element => {
  const [globalLoading, setGlobalLoading] = useState(true);
  const [user, setUser] = useState<any>();
  const [clinic, setClinic] = useState<any>();
  const [apiClient, setApiClient] = useState<ApiClient>(props.apiClient);
  const [userPermissions, setUserPermissions] = useState();

  const getProfileData = async () => {
    const token: any = apiClient.token;
    if (!token) {
      return;
    }

    const updatedUser = await apiClient.getProfile();
    setUser(updatedUser);

    const updatedClinic = await apiClient.getClinic();
    setClinic(updatedClinic);
  };

  const updatePermissions = async (user: any) => {
    const updatedUserPermissions = await apiClient.getUserPermissions(
      user.profile.id
    );
    updatedUserPermissions["type"] = user.profile.type;

    setCookies("user-p", updatedUserPermissions, {
      // maxAge: 3600,
    });
    setUserPermissions(updatedUserPermissions);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    user && updatePermissions(user);
  }, [user]);

  return (
    <ApiContext.Provider
      value={{
        globalLoading,
        setGlobalLoading,
        user,
        setUser,
        clinic,
        setClinic,
        apiClient,
        setApiClient,
        userPermissions,
        setUserPermissions,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export default ApiContextProvider;
