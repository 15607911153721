const colors = {
  black: "hsl(204, 19%, 16%)",
  white: "#ffffff",
  yellow: { "50": "hsl(48, 95%, 70%)", "100": "hsl(39, 98%, 63%)" },
  red: {
    "500": "hsl(0, 64%, 58%)",
    "700": "hsl(0, 60%, 44%)",
  },
  green: {
    "50": "hsl(158, 76%, 59%)",
    "100": "hsl(128, 50%, 49%)",
  },
  orang: "hsl(32, 94%, 55%)",
  gray: {
    50: "hsl(0, 0%, 95%)",
    100: "hsl(0, 0%, 89%)",
    150: "hsl(0, 0%, 51%)",
    200: "hsl(0, 0%, 81%)",
    300: "hsl(0, 0%, 72%)",
    400: "hsl(0, 0%, 65%)",
    500: "hsl(0, 0%, 64%)",
    600: "hsl(0, 0%, 48%)",
    700: "hsl(0, 0%, 47%)",
    750: "hsl(0, 0%, 21%)",
    760: "hsl(0, 0%, 20%)",
    800: "hsl(0, 0%, 27%)",
    900: "hsl(0, 0%, 11%)",
  },
  blue: {
    50: "hsl(213, 100%, 98%)",
    100: "hsla(200, 100%, 78%, 0.7)",
    200: "hsla(200, 100%, 78%, 1)",
    300: "hsla(206, 89%, 53%, 1)",
    500: "hsl(216, 82%, 50%)",
    600: "hsl(216, 89%, 67%)",
    700: "hsl(216, 100%, 32%)",
    800: "hsl(218, 14%, 11%)",
    900: "hsl(223, 21%,30%)",
  },
};

export default colors;
