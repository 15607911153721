import { getCookie } from "cookies-next";
import { stringify } from "qs";

import { AuthRequest, RegestrationRequest } from "./ApiTypes";

export default class ApiClient {
  baseUrl: string;
  headers: { [key: string]: any };
  _token: unknown;

  get token() {
    if (this._token) return this._token;
    return getCookie(`${process.env.NEXT_PUBLIC_TOKEN_KEY}`);
  }

  constructor(baseUrl: string, token?: unknown) {
    this.baseUrl = baseUrl;
    this.headers = {
      "Content-Type": "application/json",
    };
    this._token = token;
  }

  // Auth
  auth(data: AuthRequest) {
    return this.$fetch("POST", "/auth/login", {
      body: data,
      noAuth: true,
    });
  }

  signout() {
    return this.$fetch("DELETE", "/auth");
  }

  registration(data: RegestrationRequest) {
    return this.$fetch("POST", "/auth/signup", {
      body: data,
      noAuth: true,
    });
  }

  // users
  getUsers(query: {
    page?: number;
    limit?: any;
    profileType?: string;
    q?: string;
    profile_id?: number;
  }) {
    return this.$fetch("GET", `/users`, { query });
  }

  getUser(id: number) {
    return this.$fetch("GET", `/users/${id}`);
  }

  editUser(data: any) {
    return this.$fetch("PUT", `/users/${data.id}`, {
      body: data,
    });
  }

  createUser(data: any) {
    return this.$fetch("POST", `/users`, {
      body: data,
    });
  }

  deleteUser(id: number) {
    return this.$fetch("DELETE", `/users/${id}`);
  }

  // permssions
  getPermissions() {
    return this.$fetch("GET", `/permissions`);
  }

  getUserPermissions(id: number) {
    return this.$fetch("GET", `/permissions/${id}`);
  }

  setUserPermissions(id: number, data: any) {
    return this.$fetch("PUT", `/permissions/${id}`, {
      body: data,
    });
  }

  // profile
  getProfile() {
    return this.$fetch("GET", `/profile`);
  }

  editProfile(data: any) {
    return this.$fetch("PUT", `/profile`, {
      body: data,
    });
  }

  // clinic
  editClinic(data: any) {
    return this.$fetch("PUT", `/clinic`, {
      body: data,
    });
  }

  getClinic() {
    return this.$fetch("GET", `/clinic`);
  }

  // patients
  getPatients(query: { page?: number; limit?: any; q?: any }) {
    return this.$fetch("GET", `/patients`, { query });
  }

  getPatient(id: number) {
    return this.$fetch("GET", `/patients/${id}`);
  }

  getPatientOverview(id: number) {
    return this.$fetch("GET", `/patients/${id}/overview`);
  }

  createPatient(data: any) {
    return this.$fetch("POST", `/patients`, {
      body: data,
    });
  }

  editPatient(data: any) {
    return this.$fetch("PUT", `/patients/${data.id}`, {
      body: data,
    });
  }

  // operations
  getOperations(query: {
    page?: number;
    limit?: any;
    categories?: number;
    q?: string;
    sortDir?: any;
  }) {
    query["sortDir"] = "desc";
    return this.$fetch("GET", `/operations`, { query });
  }

  getOperation(id: number) {
    return this.$fetch("GET", `/operations/${id}`);
  }

  createOperation(data: any) {
    return this.$fetch("POST", `/operations`, {
      body: data,
    });
  }

  editOperation(data: any) {
    return this.$fetch("PUT", `/operations/${data.id}`, {
      body: data,
    });
  }

  deleteOperation(id: number) {
    return this.$fetch("DELETE", `/operations/${id}`);
  }

  getOperationsHelperData() {
    return this.$fetch("GET", `/metadata/operations`);
  }

  createCategory(data: any) {
    return this.$fetch("POST", `/metadata/categories`, {
      body: data,
    });
  }

  // treatments
  getPatientTreatmentPlans(query: {
    finished_at?: string;
    profile_id?: number;
  }) {
    return this.$fetch("GET", `/treatments`, {
      query,
    });
  }

  getTreatmentOperations(treatment_id: number) {
    return this.$fetch("GET", `/treatments/${treatment_id}`);
  }

  getTreatmentOperation(operation_id: number) {
    return this.$fetch("GET", `/treatments/operations/${operation_id}`);
  }

  deleteTreatment(id: number) {
    return this.$fetch("DELETE", `/treatments/${id}`);
  }

  editTreatment(data: any) {
    return this.$fetch("PUT", `/treatments/${data.id}`, {
      body: data,
    });
  }

  createTreatmentPlan(data: any) {
    return this.$fetch("POST", `/treatments`, {
      body: data,
    });
  }

  createTreatmentOperation(data: any) {
    return this.$fetch("POST", `/treatments/operations`, {
      body: data,
    });
  }

  createTreatmentSchedule(data: any) {
    return this.$fetch("POST", `/treatments/schedules`, {
      body: data,
    });
  }

  editTreatmentOperation(data: any) {
    return this.$fetch("PUT", `/treatments/operations/${data.id}`, {
      body: data,
    });
  }

  editTreatmentSchedule(data: any) {
    return this.$fetch("PUT", `/treatments/schedules/${data.id}`, {
      body: data,
    });
  }

  deleteTreatmentOperation(id: number) {
    return this.$fetch("DELETE", `/treatments/operations/${id}`);
  }

  deleteTreatmentSchedule(id: number) {
    return this.$fetch("DELETE", `/treatments/schedules/${id}`);
  }

  getTeethChart() {
    return this.$fetch("GET", `/metadata/treatments`);
  }

  // calendar
  getCalendarConfig() {
    return this.$fetch("GET", `/calendar/configs`);
  }

  editCalendarConfig(data: any) {
    return this.$fetch("PUT", `/calendar/configs`, {
      body: data,
    });
  }

  getCalendarData(query: {
    doctor_profile_id?: number;
    patient_profile_id?: number;
    from?: string;
    to?: string;
  }) {
    return this.$fetch("GET", `/calendar`, { query });
  }

  getAppointments(query: { from?: string; to?: string; view?: any }) {
    return this.$fetch("GET", `/calendar/appointments`, { query });
  }

  getAppointment(appointment_id: number) {
    return this.$fetch("GET", `/calendar/appointments/${appointment_id}`);
  }

  createAppointment(data: any) {
    return this.$fetch("POST", `/calendar/appointments`, {
      body: data,
    });
  }

  editAppointment(data: any) {
    return this.$fetch("PUT", `/calendar/appointments/${data.id}`, {
      body: data,
    });
  }

  createAppointmentNote(data: any) {
    return this.$fetch("POST", `/calendar/notes`, {
      body: data,
    });
  }

  deleteAppointmentNote(id: number) {
    return this.$fetch("DELETE", `/calendar/notes/${id}`);
  }

  deleteAppointment(id: number) {
    return this.$fetch("DELETE", `/calendar/appointments/${id}`);
  }

  // block off
  createBlockOff(data: any) {
    return this.$fetch("POST", `/calendar/blocksoff`, {
      body: data,
    });
  }

  getBlockoff(blockoff_id: number) {
    return this.$fetch("GET", `/calendar/blocksoff/${blockoff_id}`);
  }

  editBlockOff(data: any) {
    return this.$fetch("PUT", `/calendar/blocksoff/${data.id}`, {
      body: data,
    });
  }

  deleteBlockOff(id: number) {
    return this.$fetch("DELETE", `/calendar/blocksoff/${id}`);
  }

  // change password

  changePassword(data: any, token?: any) {
    return this.$fetch("POST", `/auth/change-password`, {
      body: data,
      token,
    });
  }

  resetPassword(data: any) {
    return this.$fetch("POST", `/auth/reset-password`, {
      body: data,
    });
  }

  //medical history
  getPatientMedicalHistory(patient_profile_id: number) {
    return this.$fetch("GET", `/patients/${patient_profile_id}/history`);
  }

  editPatientMedicalHistory(data: any) {
    return this.$fetch("PUT", `/patients/${data.profile_id}/history`, {
      body: data,
    });
  }

  // patient document
  getPatientDocuments(profile_id: number) {
    return this.$fetch("GET", `/patients/${profile_id}/documents`);
  }

  addPatientDocument(data: any) {
    return this.$fetch(
      "POST",
      `/patients/${data.patient_profile_id}/documents`,
      {
        body: data,
      }
    );
  }

  getOnePatientDocument(patient_profile_id: number, document_id: number) {
    return this.$fetch(
      "GET",
      `/patients/${patient_profile_id}/documents/${document_id}`
    );
  }

  deletePatientDocument(patient_profile_id: number, document_id: number) {
    return this.$fetch(
      "DELETE",
      `/patients/${patient_profile_id}/documents/${document_id}`
    );
  }

  editPatientDocument(data: any) {
    return this.$fetch(
      "PUT",
      `/patients/${data.patient_profile_id}/documents/${data.id}`,
      {
        body: data,
      }
    );
  }

  // upload files
  uploadFile(data: any) {
    return this.$fetch("POST", `/upload`, {
      formdata: data,
    });
  }

  // treatment quotation
  getQuotations(patient_profile_id: number) {
    return this.$fetch("GET", `/quotations?patient=${patient_profile_id}`);
  }

  getOneQuotation(quotation_id: number) {
    return this.$fetch("GET", `/quotations/${quotation_id}`);
  }

  createQuotation(data: any) {
    return this.$fetch("POST", `/quotations`, {
      body: data,
    });
  }

  editQuotation(data: any) {
    return this.$fetch("PUT", `/quotations/${data.id}`, {
      body: data,
    });
  }

  // Invoices
  getInvoices(query: { patient: number }) {
    return this.$fetch("GET", `/invoices`, { query });
  }

  getInvoice(quotation_id: number) {
    return this.$fetch("GET", `/invoices/${quotation_id}`);
  }

  createInvoice(data: any) {
    return this.$fetch("POST", `/invoices`, {
      body: data,
    });
  }

  editInvoice(invoiceId: number, data: any) {
    return this.$fetch("PUT", `/invoices/${invoiceId}`, {
      body: data,
    });
  }

  // bills
  getPaymentInfo(profileId: number) {
    return this.$fetch("GET", `/payments/profile/${profileId}/summary`);
  }

  getBills(query: { patient: number }) {
    return this.$fetch("GET", `/payments`, { query });
  }

  getBill(billId: number) {
    return this.$fetch("GET", `/payments/${billId}`);
  }

  createBill(data: any) {
    return this.$fetch("POST", `/payments`, {
      body: data,
    });
  }

  // statements
  getStatements(query: { patient: number }) {
    return this.$fetch("GET", `/statements`, { query });
  }

  // subscription
  getPlans() {
    return this.$fetch("GET", `/subscriptions`);
  }

  subscribePlan(data: any) {
    return this.$fetch("POST", `/subscriptions/pay`, {
      body: data,
    });
  }

  subscriptionHistory() {
    return this.$fetch("GET", `/subscriptions/me`);
  }

  // dashboard
  getDashboardStatistics(query: {
    from: string;
    to: string;
    tab: string;
    interval: string;
  }) {
    return this.$fetch("GET", `/statistics`, { query });
  }

  // activity logs
  getActivityLogs(query: {
    page?: number;
    limit?: any;
    q?: any;
    resource?: any;
    action?: any;
  }) {
    return this.$fetch("GET", `/activitylogs`, { query });
  }

  getActivityLogResources() {
    return this.$fetch("GET", `/metadata/activitylogs`);
  }

  // currency
  getCurrency(currencyId: number) {
    return this.$fetch("GET", `/currencies/${currencyId}`);
  }

  async $fetch(method: string, endpoint: string, options?: any) {
    let url = `${this.baseUrl}${endpoint}`;

    if (options?.query)
      url += `?${stringify(options.query, { skipNulls: true })}`;

    let body;
    let headers = { ...this.headers };

    if (options?.body) body = JSON.stringify(options.body);

    if (options?.formdata) {
      body = options?.formdata;
      delete headers["Content-Type"];
    }

    if (options?.headers) headers = { ...headers, ...options.headers };

    if (!options?.noAuth) headers["Authorization"] = `Bearer ${this.token}`;

    if (options?.token) headers["Authorization"] = `Bearer ${options?.token}`;

    const res = await fetch(url, {
      method: method,
      headers: headers,
      body: body,
    });
    const json = await res.json();

    if (res.ok) return json;

    throw { status: res.status, ...json };
  }
}
